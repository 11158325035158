<script setup lang="ts">
import type { Article, Link } from "../../models";
const { readMore, article } = defineProps<{
  readMore: Link;
  article: Article;
}>();

// Computed property to get background style only if medium URL exists
const backgroundStyle = computed(() => {
  if (article.banner?.formats?.medium?.url) {
    return { backgroundImage: `url(${article.banner.formats.medium.url})` };
  }
  return {}; // Return empty object if URL doesn't exist
});
</script>
<template>
  <NuxtLink
    :to="readMore.url"
    :title="article.title"
    class="card-article col-xs-12 col-md-4"
  >
    <div class="card-article-container">
      <div class="card-article-title">
        <div class="card-article-title-bg" :style="backgroundStyle">&nbsp;</div>
        <h2>{{ article.title }}</h2>
      </div>
      <div class="card-article-infos">
        <div class="card-article-infos-date-time">
          <span class="card-article-infos-date-time--date">{{
            dayjs(article.publishedAt).format(DATE_FORMAT)
          }}</span>
          <span class="carousel-banner-infos-top--author">
            <i role="presentation" class="extra-logo" />
            <I18nT keypath="COMPONENTS.@AUTHORED_BY" tag="span" scope="global">
              <template #author>
                <span>{{ article.author }}</span>
              </template>
            </I18nT>
          </span>
          <span class="card-article-infos-date-time--time">
            <i role="presentation" class="extra-clock" />
            <span>{{ calculateReadingTime(article.content) }}</span>
          </span>
        </div>
        <p class="card-article-infos--infos">
          {{ sanitize(article.smallDescription) }}
        </p>
        <ButtonRead :btn-link="readMore" type="primary" />
      </div>
    </div>
  </NuxtLink>
</template>
<style lang="scss" scoped>
.card-article {
  border-radius: $border-radius-6;
  margin-bottom: $grid-spacer;
  text-decoration: none;
  color: $grey;
  &:first-child {
    margin-top: 0;
  }
  &-container {
    transition: all ease-in-out 0.4s;
    border-radius: $border-radius-6;
    @include box-shadow();
    height: 100%;
  }
  &-title {
    font-weight: 800;
    display: flex;
    align-items: flex-end;
    height: 260px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: $border-radius-6 $border-radius-6 0 0;
    &-bg {
      transition: all ease-in-out 0.4s;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      left: 0;
      top: 0;
      background-position: center;
      background-size: cover;
    }
    h2 {
      padding: $grid-spacer 50px $grid-spacer $grid-spacer;
      background: rgba(0, 0, 0, 0.7);
      color: $white;
      margin: 0;
      position: relative;
      display: flex;
      align-items: center;
      min-height: 113px;
      width: 100%;
      @include mq(desktop) {
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
  &-infos {
    display: flex;
    flex-direction: column;
    height: 215px;
    padding: $grid-spacer;
    &-date-time {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: $grey-medium;
      &--date {
        font-weight: 800;
      }
      span {
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-right: 5px;
        span {
          display: inline-block;
        }
        i {
          margin-right: 5px;
          &:before {
            color: $grey-medium;
          }
        }
      }
    }
    &--infos {
      flex: 1;
      margin-bottom: $grid-spacer;
      max-height: 95px;
      overflow: hidden;
    }
  }
  &:hover {
    .card-article-container {
      transition: all ease-in-out 0.4s;
      @include box-shadow-hover();
      .card-article-title {
        &-bg {
          transform: scale(1.1);
          transition: all ease-in-out 0.4s;
          background-position: center;
        }
      }
    }
  }
}
</style>
